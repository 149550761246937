a {
    text-decoration: none;
    font-weight: 500;
    font-size: large;
    font-family:'Lato';
    margin-left: 50px;
}


ul li {
    text-decoration: none;

}

li:visited {
    color: black;
    text-decoration: none;
}