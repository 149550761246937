
body{
}
html{
  font-family: 'Raleway', sans-serif;
    overflow: scroll;
      overflow-x: hidden;
      scroll-behavior: smooth;
}
.App {
  text-align: center;
}


::-webkit-scrollbar {
  width: 0;
  background: transparent;
  scrollbar-width:none
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #00000000;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Raleway', sans-serif;
  color: white;

  
}

.App-link {
  color: #61dafb;
}

.viewer-download-button {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.videoItem{
  cursor: pointer;
}.classItem{
  cursor: pointer;
}

    video::-internal-media-controls-download-button {
      display: none;
    }
  
    video::-webkit-media-controls-enclosure {
      overflow: hidden;
    }
  
    video::-webkit-media-controls-panel {
      width: calc(100% + 30px);
    }